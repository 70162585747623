<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="serviziSorted"
      sort-by="key"
      class="elevation-1"
      dense
      :items-per-page="100"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat dense><div class="text-h6">Servizi candidati</div> 
        </v-toolbar>
      </template>
      <template v-slot:[`item.validato`]="{ item }">
        <v-simple-checkbox v-model="item.validato" disabled />
      </template>      
      <template v-slot:no-data>
        Nessun servizio trovato
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import _ from 'lodash'
  
  export default {
    props: {
      servizi: Array
    },
    data: (vm) => ({
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'servizio'
        },
        { text: 'Fornitore', value: 'fornitore' },
        { text: 'Validato', value: 'validato' }
      ]
    }),
    computed: {
      serviziSorted() {
        const noSito = this.servizi.filter(x => !x.servizio.startsWith('Sito Internet'))
        const sito = this.servizi.filter(x => x.servizio.startsWith('Sito Internet'))
        return sito.concat(_.sortBy(noSito, ['servizio']))
      }
    },
    methods: {
    }
  }
</script>
