var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"show-select":_vm.totaleEnti > 0,"item-key":"ente","headers":_vm.headers,"items":_vm.enti,"options":_vm.options,"loading":_vm.loading,"footer-props":{
          itemsPerPageOptions: [5, 10, 15, 50, 100]
        },"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Enti per Asseverazione 1.4.1")]),_c('v-spacer'),_c('span',{staticClass:"mr-10"},[_vm._v("Totale enti: "+_vm._s(_vm.numeroEnti))]),_c('span',{staticClass:"mr-10"},[_vm._v("Multifornitore: "+_vm._s(_vm.numeroMultifornitore))]),_c('span',{staticClass:"mr-10"},[_vm._v("In asseverazione: "+_vm._s(_vm.numeroInAsseverazione))]),_c('span',[_vm._v("Asseverabili: "+_vm._s(_vm.numeroAsseverabili))])],1),_c('filtri',{on:{"ricerca":_vm.onRicerca}})]},proxy:true},{key:"item.area",fn:function(ref){
        var item = ref.item;
return [_c('span',{attrs:{"title":((item.regione) + " - " + (item.provincia))}},[_vm._v(_vm._s(item.area)+" ("+_vm._s(item.provincia)+")")])]}},{key:"item.ente",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.ente.replace('Comune di ', '').replace('Comune ', '').replace('COMUNE DI ', '')))]),_c('span',{staticClass:"mr-5 text-caption"},[_c('a',{attrs:{"href":item.linkCandidatura,"target":"_blank","title":"Apre la candidatura su Salesforce"}},[_vm._v("Candidatura")])]),_c('span',{staticClass:"text-caption"},[_c('a',{attrs:{"href":item.urlSito,"target":"_blank","title":"Apre il sito web del comune"}},[_vm._v("Sito web")])])]}},{key:"item.statoCrawler",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":item.reportCrawler}},[_vm._v(_vm._s(item.statoCrawler))])]}},{key:"item.inAsseverazione",fn:function(ref){
        var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.inAsseverazione),callback:function ($$v) {_vm.$set(item, "inAsseverazione", $$v)},expression:"item.inAsseverazione"}})]}},{key:"item.asseverabile",fn:function(ref){
        var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.asseverabile),callback:function ($$v) {_vm.$set(item, "asseverabile", $$v)},expression:"item.asseverabile"}})]}},{key:"item.dataUltimoEsitoCrawler",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.dataUltimoEsitoCrawler))+" ")]}},{key:"item.elencoFornitori",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.elencoFornitori)}})]}},{key:"item.ggAsseverazione",fn:function(ref){
        var item = ref.item;
return [_c('span',{attrs:{"title":("In asseverazione da: " + (_vm.formatDate(item.dataIngressoAsseverazione)))}},[_vm._v(_vm._s(item.ggAsseverazione))])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDataFromApi}},[_vm._v("Ricarica")])]},proxy:true},{key:"header.inAsseverazione",fn:function(){return [_c('span',{attrs:{"title":"Ente in asseverazione"}},[_vm._v("In Ass.")])]},proxy:true},{key:"header.asseverabile",fn:function(){return [_c('span',{attrs:{"title":"Ente asseverabile"}},[_vm._v("Assev.")])]},proxy:true},{key:"header.statoCrawler",fn:function(){return [_c('span',{attrs:{"title":"Stato crawler o numero errori (+link crawler)"}},[_vm._v("Crawler")])]},proxy:true},{key:"header.ggAsseverazione",fn:function(){return [_c('span',{attrs:{"title":"Numero di giorni in asseverazione"}},[_vm._v("#Giorni")])]},proxy:true},{key:"header.numeroServizi",fn:function(){return [_c('span',{attrs:{"title":"Numero di servizi candidati"}},[_vm._v("#Servizi")])]},proxy:true},{key:"header.numeroValidati",fn:function(){return [_c('span',{attrs:{"title":"Numero di servizi già validati"}},[_vm._v("#Validati")])]},proxy:true},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"entiSelected",attrs:{"colspan":headers.length}},[_c('v-tabs',{attrs:{"dense":""},on:{"change":function($event){return _vm.onChangeTabs(item, $event)}},model:{value:(_vm.tab[item.ente]),callback:function ($$v) {_vm.$set(_vm.tab, item.ente, $$v)},expression:"tab[item.ente]"}},[_c('v-tab',{staticClass:"entiSelected"},[_vm._v("Servizi")]),_c('v-tab',[_vm._v("Esiti"),(item.loadingDetail)?_c('v-btn',{attrs:{"icon":"","loading":true}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e()],1),_c('v-tab',[_vm._v("Report crawler"),(item.loadingDetail)?_c('v-btn',{attrs:{"icon":"","loading":true}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e()],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab[item.ente]),callback:function ($$v) {_vm.$set(_vm.tab, item.ente, $$v)},expression:"tab[item.ente]"}},[_c('v-tab-item',[_c('dettaglio-servizi',{attrs:{"servizi":item.servizi}})],1),_c('v-tab-item',[_c('esiti',{attrs:{"esiti":item.esiti,"crawler":item.crawler,"loading":item.loadingDetail},on:{"refreshRiepilogo":function($event){return _vm.refreshRiepilogo(item)}}})],1),_c('v-tab-item',[_c('report-crawler',{attrs:{"report":item.htmlCrawler}})],1)],1)],1)]}},{key:"footer.prepend",fn:function(){return [_c('v-btn',{staticClass:"ml-5",attrs:{"small":"","color":"info","title":"Gestisce la selezione di tutti gli enti caricati(anche pagine successive)"},on:{"click":function($event){$event.stopPropagation();return _vm.onSelezionaTutti.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.selezionaTutti ? 'Annulla selezione' : 'Seleziona tutti'))]),_c('v-btn',{staticClass:"ml-5",attrs:{"disabled":_vm.selected.length === 0,"small":"","color":"success","title":"Esporta su Excel gli enti selezionati"},on:{"click":function($event){$event.stopPropagation();return _vm.onEsportaExcel.apply(null, arguments)}}},[_vm._v("Esporta su Excel")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }