<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="2">Filtri</v-col>
            <v-col cols="8" class="text--secondary">
              <span  v-if="open" key="0">Imposta i filtri di visualizzazione</span>
              <span v-else key="1">
                <!-- <v-chip class="mr-1" v-for="area in filtro.aree" :key="area.codice" :label="area.codice">{{ area.codice }}</v-chip> -->
                <v-chip class="mr-1" label  color="primary" outlined v-for="x in unionFiltri" :key="x">{{ x }}</v-chip>
                <v-chip v-if="filtro.ente" class="mr-1" label color="primary" outlined>{{ filtro.ente }}</v-chip>
                <v-chip v-if="filtro.asseveratore" class="mr-1" label color="primary" outlined>{{ filtro.asseveratore }}</v-chip>
                <v-chip v-if="filtro.fornitore" class="mr-1" label color="primary" outlined>{{ filtro.fornitore }}</v-chip>
                <v-chip v-if="filtro.servizio" class="mr-1" label color="primary" outlined>{{ filtro.servizio }}</v-chip>
                <v-chip class="mr-1" v-if="filtro.asseverazione" label color="primary" outlined>In asseverazione</v-chip>
                <v-chip class="mr-1" v-if="filtro.asseverabili" label color="primary" outlined>Asseverabili</v-chip>               
                <v-chip class="mr-1" v-if="filtro.singoloFornitore" label color="primary" outlined>Singolo fornitore</v-chip>               
                <v-chip class="mr-1" v-if="filtro.multiFornitore" label color="primary" outlined>Multifornitore</v-chip>               
                <v-chip class="mr-1" v-if="filtro.soloInformato" label color="primary" outlined>Solo cittadino informato</v-chip>
                <v-chip class="mr-1" v-if="filtro.koCrawler" label color="primary" outlined>Solo KO Crawler</v-chip>
                <v-chip class="mr-1" v-if="filtro.validati" label color="primary" outlined>Solo validati</v-chip>
              </span>
            </v-col>
            <v-col cols="1" v-if="open"><v-btn @click.stop="onFiltra">Ricerca</v-btn></v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row align="center" dense>
          <v-col xs="12" sm="6" md="4" xl="2">
            <v-select v-model="filtro.area" :items="aree" dense chips small-chips flat outlined multiple label="Area" item-text="descrizione" item-value="codice" clearable hide-details></v-select>
          </v-col>
          <v-col xs="12" sm="6" md="4" xl="3">
            <v-text-field v-model="filtro.ente" dense outlined label="Ente" clearable @keydown.enter="onFiltra" hide-details @click:clear="onClear"></v-text-field>
          </v-col>
          <v-col xs="12" sm="6" md="4" xl="2">
            <v-text-field v-model="filtro.asseveratore" dense outlined label="Asseveratore" clearable @keydown.enter="onFiltra" hide-details @click:clear="onClear"></v-text-field>
          </v-col>               
          <v-col xs="12" sm="6" md="4" xl="2">
            <v-text-field v-model="filtro.fornitore" dense outlined label="Fornitore" clearable @keydown.enter="onFiltra" hide-details @click:clear="onClear"></v-text-field>
          </v-col>
          <v-col xs="12" sm="6" md="4" xl="3">
            <v-text-field v-model="filtro.servizio" dense outlined label="Servizio" clearable @keydown.enter="onFiltra" hide-details @click:clear="onClear"></v-text-field>
          </v-col>          
        </v-row>
        <v-row align="center" dense>
          <v-col md="2">
            <v-menu
              v-model="menuStart"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedStartFormatted"
                  label="Crawler dalla data"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  hide-details
                  @click:clear="filtro.start = null"
                ></v-text-field>
              </template>
              <v-date-picker v-model="filtro.start" no-title scrollable @input="menuStart = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-menu
              v-model="menuEnd"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedEndFormatted"
                  label="Crawler alla data"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  hide-details
                  @click:clear="filtro.end = null"
                ></v-text-field>
              </template>
              <v-date-picker v-model="filtro.end" no-title scrollable @input="menuEnd = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-switch v-model="filtro.asseverazione" class="mt-0" dense inset label="In asseverazione" title="Solo enti in asseverazione" hide-details="auto"></v-switch>
          </v-col>
          <v-col md="2">
            <v-switch v-model="filtro.asseverabili" class="mt-0" dense inset label="Asseverabili" title="Solo enti con tutti i servizi asseverabili" hide-details="auto"></v-switch>
          </v-col>
          <v-col md="2">
            <v-switch v-model="filtro.singoloFornitore" class="mt-0" dense inset label="Singolo fornitore" title="Solo enti con un unico fornitore" hide-details="auto"></v-switch>
          </v-col>
          <v-col md="2">
            <v-switch v-model="filtro.multiFornitore" class="mt-0" dense inset label="Multifornitore" title="Solo enti con un più di un fornitore" hide-details="auto"></v-switch>
          </v-col>          
        </v-row>
        <v-row align="start" dense>
          <v-col md="2">
            <v-menu
              v-model="menuStartAss"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedStartAssFormatted"
                  label="In asseverazione dalla data"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  hide-details
                  @click:clear="filtro.startAss = null"
                ></v-text-field>
              </template>
              <v-date-picker v-model="filtro.startAss" no-title scrollable @input="menuStartAss = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-menu
              v-model="menuEndAss"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedEndAssFormatted"
                  label="In asseverazione alla data"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  hide-details
                  @click:clear="filtro.endAss = null"
                ></v-text-field>
              </template>
              <v-date-picker v-model="filtro.endAss" no-title scrollable @input="menuEndAss = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-switch v-model="filtro.soloInformato" class="mt-0" dense inset label="Solo Cittadino Informato" title="Solo enti che hanno candidato unicamente Cittadino Informato" hide-details="auto"></v-switch>
          </v-col>
          <v-col md="2">
            <v-switch v-model="filtro.koCrawler" class="mt-0" dense inset label="KO Crawler" title="Solo enti che hanno il crawler in errore" hide-details="auto"></v-switch>
          </v-col>
          <v-col md="2">
            <v-switch v-model="filtro.validati" class="mt-0" dense inset label="Solo validati" title="Solo enti che hanno tutti i servizi validati" hide-details="auto"></v-switch>
          </v-col>          
        </v-row>        
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  // import configurazioni from '@/services/configurazioniService.js'
  import moment from 'moment'
  export default {
    data: (vm) => ({
      filtro: {
        area: [],
        ente: '',
        asseveratore: '',
        fornitore: '',
        servizio: '',
        asseverazione: true,
        asseverabili: true,
        singoloFornitore: false,
        multiFornitore: false,
        soloInformato: false,
        koCrawler: false,
        validati: false,
        start: null,
        end: moment().utcOffset(0, true).format('YYYY-MM-DD'),
        startAss: null,
        endAss: moment().utcOffset(0, true).format('YYYY-MM-DD')
      },
      menuStart: false,
      menuEnd: false,
      menuStartAss: false,
      menuEndAss: false,      
      startFormatted: vm.formatDate(moment().add(-15, 'days').utcOffset(0, true).format('YYYY-MM-DD')),
      endFormatted: vm.formatDate(moment().utcOffset(0, true).format('YYYY-MM-DD')),
      startFormattedAss: vm.formatDate(moment().add(-15, 'days').utcOffset(0, true).format('YYYY-MM-DD')),
      endFormattedAss: vm.formatDate(moment().utcOffset(0, true).format('YYYY-MM-DD')),
      aree: []
    }),
    async mounted() {
      ['CENTRO', 'LOMBARDIA', 'NORD_EST', 'NORD_OVEST', 'SUD_EST','SUD_OVEST'].forEach(x => this.aree.push({ codice: x, descrizione: x}))
      this.$emit('ricerca', this.filtro)
    },
    computed: {
      unionFiltri() {
        return this.filtro.area.map(x => x)
      },
      computedStartFormatted () {
        return this.formatDate(this.filtro.start)
      },
      computedEndFormatted () {
        return this.formatDate(this.filtro.end)
      },
      computedStartAssFormatted () {
        return this.formatDate(this.filtro.startAss)
      },
      computedEndAssFormatted () {
        return this.formatDate(this.filtro.endAss)
      }         
    },
    watch: {
      'filtro.start' (val) {
        this.startFormatted = this.formatDate(this.filtro.start)
      },
      'filtro.end' (val) {
        this.endFormatted = this.formatDate(this.filtro.end)
      },
      'filtro.startAss' (val) {
        this.startAssFormatted = this.formatDate(this.filtro.startAss)
      },
      'filtro.endAss' (val) {
        this.endAssFormatted = this.formatDate(this.filtro.endAss)
      }      
    },    
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },      
      onFiltra() {
        this.$emit('ricerca', this.filtro)
      },
      async onClear() {
        await this.$nextTick()
        this.onFiltra()
      }
    }
  }
</script>
