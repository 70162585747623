<template>
  <v-list three-line>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Riepilogo Crawler<v-btn icon @click="refreshRiepilogo" :loading="loading" title="Legge il riepilogo del Crawler"><v-icon>mdi-refresh</v-icon></v-btn></v-list-item-title>
        <v-list-item-subtitle>
          <v-row>
            <v-col cols="2">Performance<span class="ml-3 font-weight-bold" :class="{ 'red--text': crawler.performance < 50 }">{{ crawler.performance }}</span></v-col>
            <v-col cols="2">Criteri di Conformità<span class="ml-3 font-weight-bold" :class="{ 'red--text': crawler.modelComplianceInformation === '?' }">{{ crawler.modelComplianceInformation }}</span></v-col>
            <v-col cols="2">Raccomandazioni<span class="ml-3 font-weight-bold" :class="{ 'red--text': crawler.reccomandationsAndAdditionalTests === '?' }">{{ crawler.reccomandationsAndAdditionalTests }}</span></v-col>
            <v-col cols="2">Test aggiuntivi<span class="ml-3 font-weight-bold">{{ crawler.additionalTests }}</span></v-col>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>    
    <v-list-item v-if="esiti.esitoCittadinoAttivo">
      <v-list-item-content>
        <v-list-item-title>Cittadino attivo</v-list-item-title>
        <v-list-item-subtitle v-for="esito in esiti.esitoCittadinoAttivo.split('|')" :key="esito">{{ esito }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="esiti.esitoEsperienzaUtente">
      <v-list-item-content>
        <v-list-item-title>Esperienza Utente</v-list-item-title>
        <v-list-item-subtitle v-for="esito in esiti.esitoEsperienzaUtente.split('|')" :key="esito">{{ esito }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="esiti.esitoFunzionalita">
      <v-list-item-content>
        <v-list-item-title>Funzionalita</v-list-item-title>
        <v-list-item-subtitle v-for="esito in esiti.esitoFunzionalita.split('|')" :key="esito">{{ esito }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="esiti.esitoNormativa">
      <v-list-item-content>
        <v-list-item-title>Normativa</v-list-item-title>
        <v-list-item-subtitle v-for="esito in esiti.esitoNormativa.split('|')" :key="esito">{{ esito }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="esiti.esitoSicurezza">
      <v-list-item-content>
        <v-list-item-title>Sicurezza</v-list-item-title>
        <v-list-item-subtitle v-for="esito in esiti.esitoSicurezza.split('|')" :key="esito">{{ esito }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>   
  </v-list>
</template>
<script>
  import _ from 'lodash'
  
  export default {
    props: {
      esiti: Object,
      crawler: Object,
      loading: Boolean
    },
    data: () => ({
    }),
    computed: {
    },
    methods: {
      async refreshRiepilogo() {
        this.$emit('refreshRiepilogo')
      }
    }
  }
</script>
