'use strict'

import * as XLSX from 'xlsx'
import * as dot from 'dot-object'

// import errorService from '@/services/errorService'

async function creaExcelFromSource(source, nomeFile, separatore) {
  try {
    const response = await fetch(source)
    const dati = await response.json()
    const dotted = []
    for (let d of dati) {
      var tgt = dot.dot(d);
      dotted.push(tgt)
    }
    let ws = XLSX.utils.json_to_sheet(dotted)
    var wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Valori')
    XLSX.writeFile(wb, nomeFile, { FS: separatore || ';'})    
    // return sheet
  } catch (error) {
    console.log('error creaExcelFromSource', error)
    // errorService.onError(error, 'creaExcelFromSource')
    return ''
  }
}

async function creaExcelData(dati, nomeFile, separatore) {
  try {
    const dotted = []
    for (let d of dati) {
      var tgt = dot.dot(d);
      dotted.push(tgt)
    }
    var ws = XLSX.utils.json_to_sheet(dotted);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Valori');
    XLSX.writeFile(wb, nomeFile, { FS: separatore || ';'});
  } catch (error) {
    console.log('error creaExcelData', error)
    // errorService.onError(error, 'creaExcelData')
    return ''
  }
}

async function creaExcelFromArray(dati, nomeFile, separatore) {
  try {
    var ws = XLSX.utils.json_to_sheet(dati);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Valori');
    XLSX.writeFile(wb, nomeFile, { FS: separatore || ';'});
  } catch (error) {
    console.log('error creaExcelData', error)
    // errorService.onError(error, 'creaExcelData')
    return ''
  }
}

/* intestazioniColonneModello(dati) {
  if (!dati || dati.length === 0) {
    return []
  }
  const riga = dati[0]
  const keys = Object.keys(riga)
  const mdColonne = this.modelloItem.colonne || []
  let k = 0
  const colonne = keys.map(x => {
    const s = x.split('.')
    return { key: x, value: (mdColonne[k++].label || s[s.length - 1]).toUpperCase() }
  })
  return colonne
}
 */

export default {
  creaExcelFromSource,
  creaExcelData,
  creaExcelFromArray
}
