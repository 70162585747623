<template>
  <iframe :srcdoc="report" style="width: 100%; min-height: 800px;"></iframe>
</template>
<script>
  
  export default {
    props: {
      report: String
    },
    data: () => ({
    })
  }
</script>
